<!--
 * @Author: huadan
 * @Date: 2021-04-12 14:05:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:39:51
 * @Description: 草稿箱
-->
<style lang="less" scoped>
.draft-box {
  display: flex;
  flex-direction: column;

  .title {
    font-size: @font_size_6;
    font-weight: bold;
    margin: 30px 0 20px 20px;
  }

  .draft-table {
    margin: 0 20px;
  }
}
</style>

<template>
  <NeoPageLayout class="draft-box">
    <a-row class="title">
      <a-col :span="8">{{ $t('layout.draft_box') }}</a-col>
    </a-row>
    <!-- 草稿箱表格 -->
    <div class="draft-table">
      <a-table
        class="striped no-border"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        size="middle"
        rowKey="id"
      >
        <!-- 操作列 -->
        <span slot="action" slot-scope="text, record">
          <!-- 编辑 -->
          <a
            class="mr-3"
            @click="
              $router.push({
                path: $g.route.order_newOrder,
                query: {
                  id: record.id,
                },
              })
            "
            >{{ $t('draftBox.order_action_edit') }}</a
          >
          <!-- 删除 -->
          <a-popconfirm
            :title="$t('prompt.info', { handle: $t('delete') })"
            :ok-text="$t('confirm')"
            :cancel-text="$t('cancel')"
            @confirm="() => onDelete(record.id)"
            @cancel="() => {}"
          >
            <a href="#">{{ $t('delete') }}</a>
          </a-popconfirm>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="mt-3 fr"
        show-size-changer
        v-model="current"
        :total="total"
        :page-size="pageSize"
        @change="onChange"
        @showSizeChange="onShowSizeChange"
        :pagination="false"
      />
    </div>
  </NeoPageLayout>
</template>

<script>
export default {
  name: 'draftBox',
  data() {
    return {
      //草稿箱表格数据
      data: [],
      //草稿箱表格每页显示大小
      pageSize: 10,
      //草稿箱表格当前页
      current: 1,
      //草稿箱表格总数
      total: 0,
      loading: false,
      //草稿箱表格列
      columns: [
        {
          title: this.$t('draftBox.order_num'),
          dataIndex: 'code',
          align: 'center',
        },
        {
          title: this.$t('draftBox.order_name'),
          dataIndex: 'name',
        },
        {
          title: this.$t('draftBox.order_save_time'),
          dataIndex: 'updateDate',
        },
        {
          title: this.$t('draftBox.order_action'),
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    //草稿箱表格页码改变的回调，参数是改变后的页码及每页条数
    onChange(page, pageSize) {
      this.current = page
      this.pageSize = pageSize
      this.fetch()
    },

    //pageSize 变化的回调
    onShowSizeChange(current, size) {
      this.current = current
      this.pageSize = size
      this.fetch()
    },

    //草稿箱表格更新数据
    async fetch() {
      try {
        this.loading = true
        const data = await this.$http('orderDraftPage', {
          limit: this.pageSize,
          page: this.current,
        })
        this.loading = false
        this.data = data.list
        this.total = data.total
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    //删除
    async onDelete(id) {
      try {
        await this.$http('orderDel', [id])
        this.fetch()
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>
